import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import './MainNavigation.scss';

function MainNavigation({
  basePath,
  translations,
  isSummer,
  tripId,
  locationId,
  trip,
  locationName
}) {
  if (!translations) {
    return null;
  }

  

  const navigationTranslations = translations.navigation;

  //summer hackin...
  const summerLocation = locationId.endsWith('s');


  const tripTypeLink = summerLocation || isSummer ? (
    <Link
      to={`/hiking/${locationId}`}
      className={`mainnav-link is-hiking ${
        basePath === 'hiking' ? 'is-active' : ''
      }`}
    >
      {navigationTranslations.hiking}
    </Link>
  ) : (
    <Link
      to={`/skiing/${locationId}`}
      className={`mainnav-link is-skiing ${
        basePath === 'skiing' ? 'is-active' : ''
      }`}
    >
      {navigationTranslations.skiing}
    </Link>
  );

  return (
    <div className="mainnav-container">
      <nav className="mainnav-content">
        { tripId !== 'null' && tripId !== false  &&
        <Link
          to={`/trip/${tripId}`}
          className={`mainnav-link is-trip ${
            basePath === 'trip' ? 'is-active' : ''
          }`}
        >
          {navigationTranslations.trip}
        </Link>
        }
        { tripId === 'null'  &&
        <Link
          to={`/login`}
          className={`mainnav-link is-trip ${
            basePath === 'trip' ? 'is-active' : ''
          }`}
        >
          {navigationTranslations.trip}
        </Link>
        }
        { /*trip && trip.activities && trip.activities.schedule && trip.activities.schedule.length > 0 && */
        <Link
          to={`/activity/${tripId}`}
          className={`mainnav-link is-activity ${
            basePath === 'activity' ? 'is-active' : ''
          }`}
        >
          {navigationTranslations.activity}
        </Link>
        }
        {tripTypeLink}
        <Link
          to={`/location/${locationId}`}
          className={`mainnav-link is-location ${
            basePath === 'location' ? 'is-active' : ''
          }`}
        >
          { /* navigationTranslations.location */ } { locationName }
        </Link>
      </nav>
    </div>
  );
}

MainNavigation.propTypes = {
  basePath: PropTypes.string,
  translations: PropTypes.object,
  isSummer: PropTypes.bool,
  tripId: PropTypes.string,
  locationId: PropTypes.string,
};

export default MainNavigation;

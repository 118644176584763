const locations = {
    bad_w: 'Bad Gastein',
    isg_w: 'Ischgl',
    kap_w: 'Kaprun',
    lec_w: 'Lech-Zürs',
    saa_w: 'Saalbach',
    ant_w: 'St Anton',
    zel_w: 'Zell am See',
    can_w: 'Canazei',
    cer_w: 'Cervinia',
    luc_w: 'Champoluc',
    mad_w: 'Madonna di Campiglio',
    sau_w: 'Sauze d’Oulx',
    val_w: 'Val Gardena',
    tho_w: 'Val Thorens',
    bad_s: 'Bad Gastein',
    cer_s: 'Cervinia'

  }

  export default locations;
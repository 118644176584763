import logoSE from 'shared/logo_se.svg';
import logoNO from 'shared/logo_no.svg';
import logoFI from 'shared/logo_fi.svg';
import { MARKET_CODE, MARKET_COOKIE } from 'shared/constants';

const getMarketFallback = () => {
  const domain = document.location.hostname.split('.');
  const topDomain = domain[domain.length - 1];
  return topDomain === 'localhost' || topDomain === 'dev' ? 'se' : topDomain;
};

export const getMarket = () => {
  const fallback = getMarketFallback();

  // Get market from cookie
  const cookies = `; ${document.cookie}`;
  const cookieList = cookies.split(`; ${MARKET_COOKIE}=`);
  const saved =
    cookieList.length === 2
      ? cookieList
          .pop()
          .split(';')
          .shift()
      : null;

  return saved || fallback;
};

export const getLanguage = () => {

  const market = getMarket();
  return market === 'se' ? 'SV' : market.toUpperCase();

}

export const getLocale = () => {

  const market = getMarket();
  if (market === 'no'){
    return 'no-no';
  }
  if (market === 'fi'){
    return 'fi-fi';
  }
  else {
    return 'sv-se';
  }

}

export const getMarketAPI = () => {

  const market = getMarket();
  if (market === 'no'){
    return 'booking.alpereiser.no';
  }
  if (market === 'fi'){
    return 'booking.alppimatkat.fi';
  }
  else {
    return 'booking.alpresor.se';
  }

}


export const getApiEndpoint = () => {
  const marketCode = getMarket();

  const testingStageVariable = process.env.REACT_APP_STAGE || '';
  const testingStage = testingStageVariable.trim();

  const apiKey = `REACT_APP_API_${testingStage.toUpperCase()}_${marketCode.toUpperCase()}`;

  return process.env[apiKey];
};

export const getLogo = (marketCode) => {
  let logo = null;
  const marketId = marketCode || getMarket();
  const marketIdLower = (marketId || '').toLowerCase();

  if (marketIdLower === MARKET_CODE.se) {
    logo = logoSE;
  } else if (marketIdLower === MARKET_CODE.no) {
    logo = logoNO;
  } else if (marketIdLower === MARKET_CODE.fi) {
    logo = logoFI;
  }

  return logo;
};

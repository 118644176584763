import React from 'react';
import { updateUrlParameter } from 'helpers/urlHelper';
import { PropTypes } from 'prop-types';
import './tripSummary.scss';

function TripSummary({
  header,
  header2,
  tripSummary,
  translationSummary,
  isPaid,
  isCancelled,
  bookingConfirmationLink,
  cta,
  tripContact,
  hint1,
  hint2
}) {

  const row = (data, key) => {
    const translationHelp = translationSummary[`${key}Help`];
    const value = data[`${key}Help`];
    const helpText =
      translationHelp &&
      translationHelp.replace('{0}', value);

    return (
      <div className="trip__summary-row" key={key}>
        <div className="trip__summary-label">
          <div>{translationSummary[key]}</div>
          {value && <div className="trip__summary-help">{helpText}</div>}
        </div>
        <div className="trip__summary-spacer"></div>
        <div className="trip__summary-value">{data[key]}</div>
      </div>
    );
  };

  const statusText = isCancelled
    ? translationSummary.isCancelled
    : isPaid
    ? translationSummary.isPaid
    : null;

  return (
    <div className="trip__summary">
      <div className="trip__summary-column">
        <div className="trip__summary-title">
          <h2>{header}</h2>
          {statusText && <div className="trip__summary-paid">{statusText}</div>}
        </div>
        {tripSummary["bookingNumber"] && row(tripSummary, "bookingNumber")}
        {tripSummary["bookingDate"] && row(tripSummary, "bookingDate")}
        {tripSummary["bookingPerson"] && row(tripSummary, "bookingPerson")}
        {tripContact["email"] && row(tripContact, "email")}
        {tripContact["phoneNumber"] && row(tripContact, "phoneNumber")}
        <div className="trip__summary-buttons">
        {bookingConfirmationLink && (
          <a
            className="btn btn--transparent trip__summary-confirmation"
            href={bookingConfirmationLink.url}
          >
            {bookingConfirmationLink.text}
          </a>
        )}
        <span className="trip__summary-hint">{hint1}</span>
        </div>
      </div>
      <div className="trip__summary-column">
        <div className="trip__summary-title">
          <h2>{header2}</h2>
          {statusText && <div className="trip__summary-paid">{statusText}</div>}
        </div>
        {tripSummary["bookingFee"] && row(tripSummary, "bookingFee")}
        {tripSummary["bookingPayment"] && row(tripSummary, "bookingPayment")}
        {tripSummary["bookingPrice"] && row(tripSummary, "bookingPrice")}
        {tripSummary["paid"] && row(tripSummary, "paid")}
        {tripSummary["leftToPay"] && row(tripSummary, "leftToPay")}
        <div className="trip__summary-buttons">
        {!isPaid && cta && (
            <a className="btn trip__hero-btn" href={updateUrlParameter(cta.link, 'redirectUrl', window.location.href)}>
              {cta.text}
            </a>
        )}
        <span className="trip__summary-hint">{hint2}</span>
      </div>
    </div>
    </div>
  );
}

TripSummary.propTypes = {
  header: PropTypes.string,
  header2: PropTypes.string,
  tripSummary: PropTypes.object,
  tripContact: PropTypes.object,
  translationSummary: PropTypes.object,
  cta: PropTypes.object,
};

export default TripSummary;

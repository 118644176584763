import { callToAPI } from './apiHelper';
import { getApiEndpoint, getMarket } from './marketHelper';
import {
  loadOfflineData,
  saveOfflineData,
  shouldUpdateOfflineData,
} from './offlineHelper';
import { tripMap } from './tripHelper';

const marketCode = getMarket();
const api = getApiEndpoint();

const getData = async (offlineKey, path, setData, shouldUpdate, isLocalFile = false) => {
  const offlineData = offlineKey && (await loadOfflineData(offlineKey));
  // If user is offline
  if (!navigator.onLine) {
    setData &&
      setData({
        fetched: true,
        error: !offlineData,
        data: offlineData,
      });
  }

  // If no valid path to call
  if (!path) {
    setData &&
      setData({
        fetched: true,
        error: true,
        data: null,
      });
  }
  const url = `${ isLocalFile ? '' : api }/${path}`;
  return fetchData(offlineKey, url, setData, offlineData, shouldUpdate);
};

const fetchData = (offlineKey, url, setData, offlineData, shouldUpdate) => {
  if (!shouldUpdate) {
    setData &&
      setData({
        fetched: true,
        error: false,
        data: offlineData,
      });

    return;
  }

  return callToAPI(url).then(function(result) {

    // No response from server
    if (!result) {
      // TODO: If offline is same id - use that?
      // if (offlineData.id === WHAT?)

      setData &&
        setData({
          fetched: true,
          error: true,
          data: null,
        });

      return false;
    }
    offlineKey && saveOfflineData(offlineKey, result);

    //console.log('fetchHelper:', result)
    setData &&
      setData({
        fetched: true,
        error: false,
        data: result,
      });

    return result;
  });
};

export async function setIdFromOffline(offlineKey, setData) {
  const offlineData = await loadOfflineData(offlineKey);

  if (offlineData && offlineData.id) {
    setData(String(offlineData.id));
  } else {
    setData(null);
  }
}

export function fetchGlobals(setData) {
  const path = `globals.${marketCode}.json`;
  const shouldUpdate = shouldUpdateOfflineData('globals');
  getData('globals', path, setData, shouldUpdate, true);
}

export function fetchTrip(id, setData) {
  //const path = `trips/${id}`;

  //tripinfo
  //activities
  //addons
  Promise.all([
    callToAPI(`${api}/mytrip/activities?reservationId=${id}`), 
    callToAPI(`${api}/mytrip/reservationSummary?reservationId=${id}`),
    callToAPI(`${api}/mytrip/addons?reservationId=${id}`)
   ]).then((values) => {
    const d = { 
        summary: values[1],
        addons : values[2],
        activities: values[0]
    }
    console.log('trip: ', d)
    const tripMapped = tripMap(d)
    setData({ data : tripMapped })

   })


  /*//mytrip/activities?reservationId=86610fa6-bee3-479e-ae07-8660d12f09bf
  const path = `mytrip/activities?reservationId=${id}`;

  const shouldUpdate = shouldUpdateOfflineData('trip', id);

  //get from backoffice instead of alpresor.se
  getData('trip', path, setData, shouldUpdate);*/
}

export function fetchLocation(id, setData) {
  const path = `locations/${id}/${marketCode}`;
  const shouldUpdate = shouldUpdateOfflineData('location', id);
  getData('location', path, setData, shouldUpdate, true);
}

export function fetchWeather(id, setData) {
  const path = `locations/${id}/weather/${marketCode}`;
  const shouldUpdate = shouldUpdateOfflineData('weather', id);
  getData('weather', path, setData, shouldUpdate);
}

export function fetchNotifications(id, setData) {
  const path = `trips/${id}/notifications`;
  const shouldUpdate = shouldUpdateOfflineData('notifications', id);
  getData('notifications', path, setData, shouldUpdate);
}

export function fetchLogin(trip) {

  
  //const path = `login/${trip.bookingRef}/${trip.email}`;
  const path = `reservationId?reservationCode=${trip.bookingRef}&email=${trip.email}`;

  //get from backoffice instead of alpresor.se
  const d = getData(null, path, null, true);
  return d;
}
